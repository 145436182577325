.colors {
  color: #457b9d;
  color: #1d3557;
  color: #a8dadc;
  color: #f1faee;
  color: #e63946;
  color: #e0e0e0;
}

.user-form {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  font-family: 'Oswald';

  width: 85vw;
  margin-top: 10px;

  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0px 5px 10px 1px #1d355749;
  z-index: 10;
}

.user-form-title {
  text-align: center;
  width: 50%;
  margin-bottom: 20px;
}

.user-form-title h3 {
  padding: 0;
  margin: 0;
  line-height: 1;
  font-size: 36px;
  color: #1d3557;
}

.user-form-title p {
  margin: 0;
  font-size: 14px;
  font-weight: 100;
  letter-spacing: 2px;
}

.user-selection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 60%;
}

.user-selection-btn {
  outline: none;
  padding: 5px;
  border: 0;
  margin: 0 5px;
  background-color: transparent;
  padding: 5px 30px;
  font-family: 'Oswald';
  font-size: 16px;
  line-height: 1;
  letter-spacing: px;
  font-weight: 700;
  color: #457b9d;
  transition: all 0.2s ease;
}

.user-selection-btn-active {
  color: #1d3557;
  font-size: 18px;
}

.user-selection-anim {
  padding: 0;
  width: 210px;
}

.user-selection-anim-orig {
  left: 0;
  width: 50%;
  height: 2px;
  background-color: #1d3557;
  transition: all 0.2s ease;
}

.user-selection-anim-orig-right {
  transform: translateX(105px);
}

/* *******************LOGIN FORM******************* */

.user-login-form {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 60%;
  z-index: 11;
  animation: FadeInLogin 0.5s ease;
}

/**********************USERNAME FIELD**********************/
.user-login-form-input-style-username {
  background-color: #f7f7f7;
  border-bottom: 2px solid #457b9d;
  margin: 20px 0;
  box-shadow: 0px 5px 10px 1px rgba(155, 155, 155, 0.3);
  transition: all 0.1s linear;
  position: relative;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.user-login-form-input-style-username input {
  color: #1d3557;
  padding: 10px;
  font-size: 16px;
  font-family: 'Oswald';
  font-weight: 300;
  outline: none;
  border: 0;
  width: 90%;
  background-color: transparent;
}

.user-login-form-input-style-username::after {
  content: 'Username';
  font-family: 'Oswald';
  font-weight: 300;
  position: absolute;
  top: 10px;
  left: 10px;
  opacity: 0;
  transition: all 0.3s ease;
}

.user-login-form-input-style-username:focus-within::after {
  text-align: center;
  width: 20%;
  opacity: 1;
  font-size: 12px;
  background-color: #457b9d;
  color: white;
  transform: translateY(-20px);
}

.user-login-form-input-style-username:focus-within {
  border: 2px solid #457b9d;
}

.user-login-form-input-style-username-icon {
  color: #bebebe;
  padding: 5px;
  transition: all 0.5s ease;
}

.user-login-form-input-style-username:focus-within > .user-login-form-input-style-username-icon {
  color: #1d3557;
}

.user-login-form-input-style-username input:disabled {
  color: #bebebe;
}
/************************************************************/

/**********************PASSWORD FIELD**********************/
.user-login-form-input-style-password {
  background-color: #f7f7f7;
  border-bottom: 2px solid #457b9d;
  margin: 20px 0;
  box-shadow: 0px 5px 10px 1px rgba(155, 155, 155, 0.3);
  transition: all 0.1s linear;
  position: relative;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.user-login-form-input-style-password input {
  color: #1d3557;
  padding: 10px;
  font-size: 16px;
  font-family: 'Oswald';
  font-weight: 300;
  outline: none;
  border: 0;
  width: 90%;
  background-color: transparent;
}

.user-login-form-input-style-password::after {
  content: 'Password';
  font-family: 'Oswald';
  font-weight: 300;
  position: absolute;
  top: 10px;
  left: 10px;
  opacity: 0;
  transition: all 0.3s ease;
}

.user-login-form-input-style-password:focus-within::after {
  text-align: center;
  width: 20%;
  opacity: 1;
  font-size: 12px;
  background-color: #457b9d;
  color: white;
  transform: translateY(-20px);
}

.user-login-form-input-style-password:focus-within {
  border: 2px solid #457b9d;
}

.user-login-form-input-style-password-icon {
  color: #bebebe;
  padding: 5px;
  transition: all 0.5s ease;
}

.user-login-form-input-style-password:focus-within > .user-login-form-input-style-password-icon {
  color: #1d3557;
}

.user-login-form-input-style-password input:disabled {
  color: #bebebe;
}

.user-login-form-input-style-password button {
  outline: none;
  border: 0;
  background-color: transparent;
  color: #6b6b6b;
}
/************************************************************/
.user-login-form-btn {
  border: 0;
  outline: none;
  background-color: #1d3557;
  padding: 20px 20px;
  font-size: 16px;
  color: #f1faee;
  box-shadow: 0px 5px 10px 1px rgba(155, 155, 155, 0.3);
  transition: all 0.2s linear;
  position: relative;
}

.user-login-form-btn > span {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.user-login-form-btn:hover {
  color: #1d3557;
  background-color: #fff;
  border: 2px solid #1d3557;
}

.user-login-form-btn:active {
  color: #1d3557;
  box-shadow: 0px 2px 10px 1px rgba(155, 155, 155, 0.8);
}

.user-login-form-btn::after {
  content: '';
  width: 100%;
  height: 100%;
  background-color: #a8dadc;
  border-radius: 25px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s ease;
}

.user-login-form-btn:active::after {
  transform: scale(1.2);
  opacity: 0;
}

/* *************************************************** */

/* *******************SIGN UP FORM******************* */

.user-signup-form {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 80%;
  z-index: 11;
  animation: FadeInSignUp 0.5s ease;
}

.user-signup-form-input-info {
  font-weight: 300;
  font-size: 12px;
  color: #457b9d;
  text-align: center;
  margin-bottom: 10px;
}

.user-signup-form label {
  font-size: 16px;
  padding-left: 10px;
  margin-bottom: 2px;
  font-weight: 300;
  color: #457b9d;
}

.user-signup-form-input-style input {
  color: #1d3557;
  padding: 10px;
  font-size: 16px;
  font-family: 'Oswald';
  font-weight: 300;
  outline: none;
  border: 0;
  width: 90%;
  background-color: transparent;
}

.user-signup-form-input-style input[name='password'],
input[name='cpassword'] {
  width: 80%;
}

.user-signup-form-input-style > button[name='btn-showpw'] {
  outline: none;
  background-color: transparent;
  border: 0;
}

.user-signup-form-input-style {
  background-color: #f7f7f7;
  border-bottom: 2px solid #457b9d;
  margin-bottom: 10px;
  box-shadow: 0px 5px 10px 1px rgba(155, 155, 155, 0.3);
  transition: all 0.1s linear;
}

.user-signup-form-input-style:focus-within {
  border: 2px solid #457b9d;
}

.user-signup-form-input-style input:disabled {
  color: #bebebe;
}

.user-signup-form-btn {
  border: 0;
  outline: none;
  background-color: #1d3557;
  padding: 20px 20px;
  font-size: 16px;
  color: #f1faee;
  box-shadow: 0px 5px 10px 1px rgba(155, 155, 155, 0.3);
  transition: all 0.2s linear;
  position: relative;
}

.user-signup-form-btn > span {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.user-signup-form-btn:hover {
  color: #1d3557;
  background-color: #fff;
  border: 2px solid #1d3557;
}

.user-signup-form-btn:active {
  color: #1d3557;
  box-shadow: 0px 2px 10px 1px rgba(155, 155, 155, 0.8);
}

.user-signup-form-btn::after {
  content: '';
  width: 100%;
  height: 100%;
  background-color: #a8dadc;
  border-radius: 25px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s ease;
}

.user-signup-form-btn:active::after {
  transform: scale(1.2);
  opacity: 0;
}

/* *************************************************** */

@keyframes FadeInLogin {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes FadeInSignUp {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
