.colors {
  color: #457b9d;
  color: #1d3557;
  color: #a8dadc;
  color: #f1faee;
  color: #e63946;
}

body {
  margin: 0;
  padding: 0;
  /* background-image: linear-gradient(to bottom, #a8dadc, #457b9d, #1d3557); */
  font-family: 'Oswald', sans-serif;
  font-family: 'Roboto', sans-serif;
}
