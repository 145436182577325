.colors {
  color: #457b9d;
  color: #1d3557;
  color: #a8dadc;
  color: #f1faee;
  color: #e63946;
  color: #e0e0e0;
}

.spinner {
  border-radius: 50%;
  animation: spinneranim 3s linear infinite;
}

.spinner-style-login {
  border: 3px solid #1d3557;
  border-top-color: transparent;
  width: 10px;
  height: 10px;
  margin: 5px;
}
.spinner-style-dashboard {
  border: 3px solid #a8dadc;
  border-top-color: transparent;
  width: 20px;
  height: 20px;
  margin: 5px;
}

.spinner-style-budgets-view {
  border: 10px solid #1d3557;
  border-top-color: transparent;
  width: 45px;
  height: 45px;
  margin: 5px;
}

@keyframes spinneranim {
  to {
    transform: rotate(360deg);
  }
}
