.colors {
  color: #457b9d;
  color: #1d3557;
  color: #a8dadc;
  color: #f1faee;
  color: #e63946;
  color: #e0e0e0;
}

.user-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Oswald';

  margin: 10px;
  padding: 10px;
  width: 85vw;
  border: 2px solid #1d3557;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0px 5px 10px 1px rgba(155, 155, 155, 0.3);
  z-index: 10;
}

.user-info span {
  font-size: 24px;
  color: #1d3557;
  line-height: 1;
}

.user-info h2 {
  margin: 0;
  padding: 0;
  width: 95%;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #1d3557;
  border-bottom: 2px solid #1d3557;
}

.user-info-field {
  display: flex;
  justify-content: flex-start;
  width: 95%;
  margin: 5px 0;
}

.user-info-field label {
  text-align: center;
  font-weight: 500;
  margin-right: 10px;
  font-size: 16px;
  width: 50%;
  border-right: 1px solid #1d3557;
}

.user-info-field input {
  font-family: 'Oswald';
  font-size: 14px;
  font-weight: 300;
  border: 0;
  border-bottom: 2px solid #1d3557;
  width: 50%;
}

.user-info-field input:disabled {
  outline: none;
  background-color: transparent;
  border: 0;
}

.user-info-msg {
  text-align: center;
}

.user-info-btn {
  border: 0;
  outline: none;
  background-color: #1d3557;
  padding: 5px 15px;
  border-radius: 25px;
  font-size: 14px;
  color: #f1faee;
  box-shadow: 0px 5px 10px 1px rgba(155, 155, 155, 0.3);
  transition: all 0.1s linear;
  margin: 5px 20px;
  position: relative;
}

.user-info-btn:hover {
  color: #1d3557;
  background-color: #fff;
  border: 2px solid #1d3557;
}

.user-info-btn:active {
  color: #1d3557;
  box-shadow: 0px 2px 10px 1px rgba(155, 155, 155, 0.8);
}

.user-info-btn-disable {
  cursor: not-allowed;
  pointer-events: none;
}
