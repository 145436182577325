.list-budgets {
  margin: 0;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.list-budgets ul {
  margin: 0;
  padding: 0;

  list-style: none;
  list-style-type: none;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.list-budgets li {
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;
}
