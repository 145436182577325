.view-controller {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 45px;
  background-color: #fff;
  z-index: 50;
}

.view-controller-btn-container {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: all 0.3s ease;
}

.view-controller-btn {
  outline: none;
  font-family: 'Oswald';
  color: #1d3557;
  border: 0;
  background-color: transparent;
  position: relative;
  width: 50%;
  height: 50%;
}

.view-controller-btn-container-active {
  border-bottom: 5px solid #1d3557;
}

.view-controller-btn::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.2s ease;
}

.view-controller-btn:active::after {
  background: #adadad;
  transform: scaleX(1.2) scaleY(1.2);
  opacity: 0;
}
